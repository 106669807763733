/* Base styles */
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

:root {
  /* Default light theme variables */
  --backgroundColor: #FFFFFF;
  --textColor: #000000;
  --buttonColor: #dddddd;
  --buttonTextColor: #000000;
}

/* Dark theme variables */
body[data-theme="dark"] {
  --backgroundColor: #17191A; /* Dark background */
  --textColor: #DCDBD8; /* White text */
  --buttonColor: #292D2F; /* Grey button */
  --buttonTextColor: #DCDBD8; /* Button text color */
}

.theme-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--textColor);
  transition: color 0.3s;
}

/* Body styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: var(--textColor) !important;
  background-color: var(--backgroundColor);
  /* color: var(--textColor); */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

/* Root container */
#root {
  padding-right: 10%;
  padding-left: 10%;
  background-color: var(--backgroundColor);
}

/* Main content styles */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Links */
a {
  text-decoration: none !important;
  color: var(--textColor);
}

/* Typography */
.montserratFont {
  font-family: 'Montserrat', sans-serif;
}

.title {
  font-weight: 700;
  text-align: center;
}

p {
  font-size: 1rem;
}

/* Theme toggle button -- if you remove it, it looks much cleaner */
.theme-toggle button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px;
  background-color: var(--buttonColor); /* Grey button */
  color: var(--buttonTextColor); /* Button text color */
  transition: background-color 0.3s, color 0.3s;
}

.theme-toggle button:hover {
  filter: brightness(0.9);
}
